.stars .filled {
  color: gold;
}

.stars .empty {
  color: lightgray;
}

.review-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5rem;
  text-align: center;
  /* margin: .5rem; */
}

.stars {
  display: flex;
  justify-content: center;
  margin-bottom: .5rem;
}

#review-btn {
  border: none;
  background-color: lightgray;
  width: 100%;
  padding: 10px;
}
