.error {
  color: red;
}

.create-spot-form {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.header-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.country-address-box input {
  margin: .5rem;
}

.city-state-box {
  display: flex;
}

.city-state-box input {
  margin: .5rem;
}

#state {
  width: 40%;
}


.description-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.title-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo-box input {
  margin: .5rem;
}

.update-spot-btn {
  border: none;
  padding: 8px;
  color: white;
  background-color: gray;
  margin-top: 1rem;
}
