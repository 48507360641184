.log-in-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

#modal-content {
  border-radius: 10px;
}



.username-email {
  margin-bottom: .5rem;
}

.password {
  margin-bottom: 1rem;
}


.log-in-btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: .5rem 0 .5rem 0;
}

.login-btn {
  width: 100%;
}

.demo-login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.demo-login-btn {
  width: 100%;
}
