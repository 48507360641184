.spot-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #1a694b;
  color: #fff;
}

.spot-tile .tooltip {
  visibility: hidden;
  background-color: #1a694b;
  margin-left: .3rem;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
  text-align: justify;
  position: absolute;
  top: .5rem;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.spot-tile:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.spot-details {
  padding: .4rem;
  letter-spacing: .8px;
}

.spot-details .location, 
.spot-details .avg-rating,
.spot-details .price {
  margin-bottom: .4rem;
}

.link {
  text-decoration: none;
  color: black;
  max-width: 300px;
}

.prev-img-box {
  width: 100%;
  height: 150px;
}

.preview-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
