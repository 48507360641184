@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.hidden {
  display: none;
}

.home-link {
  text-decoration: none;
  font-family: 'Dancing Script', cursive;
  font-weight: 700;
  color: #215a2d;
}

#create-spot-link {
  text-decoration: none;
  border: 1px solid transparent;
  padding: .4rem;
}

#create-spot-link:hover {
  border: 1px solid black;
}

#create-spot-link:visited {
  color: black;
}

#logo {
  height: 50px;
  width: 50px;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
  margin-bottom: 1rem;
  padding: 0 .8rem 0 .8rem;
}

.user-box {
  display: flex;
  align-items: center;
  right: .5rem;
}

.profile-box {
  /* border: 2px solid black; */
  padding: 0.5rem;
  border-radius: 15px;
  /* position: fixed */
}

.new-spot-box {
  margin-right: .5rem;
}

.logo-box {
  display: flex;
  align-items: center;
}

#burger-btn {
  cursor: pointer;
}
