.delete-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 10px; */
  width: 80%;
}

.btn-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-box button {
  width: 100%;
  padding: 8px;
}

#yes {
  background-color: red;
}
