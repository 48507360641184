.manage-spot-tile {
  display: flex;
  flex-direction: column;
  background-color: #1a694b;
  color: #fff;
}

.manage-sppot-details {
  padding: .4rem;
  letter-spacing: .8px;
}

.manage-spot-details .location, 
.manage-spot-details .avg-rating,
.manage-spot-details .price {
  margin: .4rem;
}

.manage-link {
  text-decoration: none;
  color: black;
  max-width: 300px;
}

.manage-prev-img-box {
  width: 100%;
  height: 150px;
}

.manage-preview-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-box button {
  border: none;
  margin: 5px;
  padding: 5px;
  background-color: gray;
  color: white;
  width: 90px;
  cursor: pointer;
}



.btn-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
