.details-page-container {
  display: flex;
  justify-content: center;
}

.details-page {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-width: 60%;
}

.gallery-container {
  display: flex;
  justify-content: center;
}

.gallery {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
  max-width: 1500px;
}

.hero-img {
  grid-column: 1 / 2;
  width: 100%;
  height: auto;
  max-height: 401px;
  object-fit: cover;
}

.small-images-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: .5rem;
}

.small-imgs {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.spot-deets {
  display: flex;
  flex-direction: column;
}

.location-deets {
  display: flex;
  gap: 1rem;
  font-weight: 600;
}

.deet {
  font-size: 1.3rem;
  border: 1px solid purple;
}

.middle-box {
  display: flex;
  justify-content: space-between;
}

.host-description {
  width: 60%;
}

.reserve-box {
  border: 1px solid hsla(0, 0%, 68%, 0.87);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  margin-top: 1rem;
  box-shadow: 0px 8px 12px 0px hsla(0, 0%, 68%, 0.87);
  width: 30%;
  max-width: 40%;
  padding: 0 1rem 0 1rem;
}

.reserve-deets {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* width: 100%; */
}

.reserve-btn-box {
  width: 100%;
  text-align: center;
}

.reserve-btn {
  width: 80%;
  padding: .5rem;
  margin-bottom: .3rem;
  background-color: red;
  color: white;
  border: none;
  border-radius: 10px;
}

.reviews-box {
  border-top: 1px solid hsla(0, 0%, 68%, 0.87);
  padding-top: 1rem;
}

#post-review {
  border: none;
  padding: 8px;
  background-color: grey;
  color: white;
}
