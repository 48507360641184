.profile-dropdown {
  list-style-type: none;
  position: absolute;
  top: 3.7rem;
  right: .8rem;
  background-color: #302f2f;
  color: #fff;
  border: 2px solid black;
  padding: 4px;
  z-index: 1;
}


.menu-btns {
  display: flex;
  align-items: center;
}

#manage-btn {
  text-decoration: none;
}

#manage-btn:visited {
  color: #fff;
}

#burger-btn {
  margin-right: 5px;
}

.user-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .4rem;
}

.manage-spots-box {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: .3rem 0 .3rem 0;
  margin: .3rem;
}

#user-log-out-btn {
  border: none;
  padding: .4rem;
  background-color: rgb(151, 150, 150);
  border: 1px solid transparent;
  cursor: pointer;
}

#user-log-out-btn:hover {
  border: 1px solid #fff;
}